import Loadable from "app/components/Loadable";
import { lazy } from "react";

const Activity = Loadable(lazy(() => import("./Activity")));
const AddActivity = Loadable(lazy(() => import("./AddActivity")));
const EditActivity = Loadable(lazy(() => import("./EditActivity")));
const ViewActivity = Loadable(lazy(() => import("./ViewActivity")));

const activityRoutes = [
  { path: "activity", element: <Activity /> },
  { path: "activity/add-activity", element: <AddActivity /> },
  { path: "activity/edit-activity", element: <EditActivity /> },
  { path: "activity/view-activity/:id", element: <ViewActivity /> },
];

export default activityRoutes;
