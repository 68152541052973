// export const topBarHeight = 64;
// export const navbarHeight = 60;
// export const sideNavWidth = 260;
// export const sidenavCompactWidth = 80;
// export const containedLayoutWidth = 1200;

export const topBarHeight = 75;
export const navbarHeight = 60;
export const sideNavWidth = 220;
export const sideNavMarginLeft = 232;
export const sidenavCompactWidth = 80;
export const sidenavCompactMarginLeft = 90;
export const containedLayoutWidth = 1200;
export const sideNavCloseMarginLeft = 0;
