import React, { useState } from "react";

export const TitleContext = React.createContext();

export const TitleProvider = ({ children }) => {

  // Defines hooks
  const [title, setTitle] = useState("Dashboard");
  const [page, setPage] = useState("Dashboard");
  const [path, setPath] = useState("/Dashboarddashboard/default");

  return (
    <TitleContext.Provider
      value={{
        title,
        setTitle,
        page,
        setPage,
        path,
        setPath,
      }}
    >
      {children}
    </TitleContext.Provider>
  );
};

export default TitleProvider;
